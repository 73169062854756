/**
 * Chart type enumerator
 *
 * @export
 * @enum {number}
 */
export enum AbChartTypeEnum {

    Bar = 'bar',
    BarHorizontal = 'horizontalBar',
    Line = 'line',
    Radar = 'radar',
    Pie = 'pie',
    Doughnut = 'doughnut',
    PolarArea = 'polarArea',
    Bubble = 'bubble',
    Scatter = 'scatter'
}